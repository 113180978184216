$(function () {
    $("body").find(".container").append("<div style='display:none;' class='overlay'></div>");

    $('#burgerIcon').click(function() {
        $("#mainMenu").toggleClass("menuShowed");
    });

    $( ".nav__item" ).each(function(index) {
        $(this).on("click", function(){
            $(this).next().toggle();
            $(this).next().siblings('.nav__item-submenu').hide();
            $('.overlay').toggle();
        });
    });

    $('.nav__item').each(function() {
        $(this).on( 'click', function() {
            $(this).toggleClass('activePage');
            $(this).siblings().removeClass('activePage');

            if($(this).hasClass( "activePage" )){
                $('.overlay').show();
            }
            else{
                $('.overlay').hide();
            }
        });
    });

    $('.nav__item-submenu').find('li').each(function() {
        $(this).on( 'click', function() {
            $("body").find(".nav__item-submenu").fadeOut();
            $('.overlay').hide();
            
        });
    });

    $(document).click(function(event) {
        if (!$(event.target).closest(".nav__item-submenu,.nav__item").length) {
          $("body").find(".nav__item-submenu").fadeOut();
          $('.nav__item').removeClass('activePage');
          $('.overlay').fadeOut();
        }
        
    });

    $(window).scroll(function(){
        var sticky = $('#navigation'),
            scroll = $(window).scrollTop();
    
        if (scroll >= 1) {
            sticky.addClass('fixed');
        }
        else {
            sticky.removeClass('fixed');
        }
    });


    /*GOOGLE MAPS*/

    $('.mapContainer').click(function () {
        $('.mapContainer iframe').css("pointer-events", "auto");
    });
    
    $( ".mapContainer" ).mouseleave(function() {
      $('.mapContainer iframe').css("pointer-events", "none"); 
    });

    

    

});

